<template>
	<div class="splash-screen">
		<video preload="auto" playsinline autoplay loop muted class="background-video">
			<source src="../assets/background/bg-video.mp4" type="video/mp4" />
		</video>
		<TextDirt text="Loading..." text-classes="text-[80px] whitespace-nowrap lg:whitespace-normal title" />
	</div>
</template>

<style scoped>
.splash-screen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	z-index: 1;
	/* Ensure this is above the video */
}

.background-video {
	position: fixed;
	/* Change to fixed to ensure it covers the entire screen */
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: -1;
}
</style>
